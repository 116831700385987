import { Box } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PURCHASES } from "../../../constants/NavbarConstants";
import { getPurchases } from "../../../redux/slices/orderSlice";
import PageTitle from "../corelist/PageTitle";
import ProgressSpinner from "../../../components/common/forms/ProgressSpinner";
import Purchases from "./Purchases";

const AllPurchases = () => {
  const dispatch = useDispatch();

  const { loading } = useSelector((state) => state.product);
  const [purchases, setPurchases] = useState([]);

  const fetchPurchases = useCallback(() => {
    // Fetch purchases
    dispatch(getPurchases({ state: "completed", limit: 10 })).then((res) => {
      setPurchases(res.payload?.items || []);
    });
  }, [dispatch]);

  useEffect(() => {
    fetchPurchases(); // Initial fetch
  }, [fetchPurchases]);

  return (
    <>
      {loading && <ProgressSpinner isLoading={loading} />}
      <PageTitle pageTitle={PURCHASES} />
      <Box mt={6} mb={6}></Box>
      <Purchases purchasedItems={purchases} />
    </>
  );
};

export default AllPurchases;
