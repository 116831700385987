import { SALES } from "../../../constants/NavbarConstants";
import PageSubTitle from "../corelist/PageSubTitle";
import ReusableListView from "../corelist/ReusableListView";
import SecondaryText from "../corelist/SecondaryText";
import useFormatUtils from "../../../hooks/useFormatUtils";

const Sales = ({ salesItems }) => {
  const { formatDate } = useFormatUtils();

  const renderSecondaryText = (item) => (
    <SecondaryText
      fields={[
        { label: "Adopted by", value: item.adoptedBy || "NA" },
        { label: "Congratulations, on", value: item.purchasedDate ? formatDate(item.purchasedDate) : "NA" },
        { label: "you made", value: `${item.sellingPrice} NOK` || "NA" },
      ]}
    />
  );

  const loadMoreItems = () => {};

  return (
    <>
      <PageSubTitle pageSubTitle={SALES} />
      <ReusableListView items={salesItems} loadMoreItems={loadMoreItems} renderSecondaryText={renderSecondaryText} />
    </>
  );
};

export default Sales;
