import { Box } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SALES } from "../../../constants/NavbarConstants";
import { getSales } from "../../../redux/slices/orderSlice";
import PageTitle from "../corelist/PageTitle";
import ProgressSpinner from "../../../components/common/forms/ProgressSpinner";
import Sales from "./Sales";

const AllSales = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.product);
  const [sales, setSales] = useState([]);

  const fetchSales = useCallback(() => {
    // Fetch purchases
    dispatch(getSales({ state: "completed", limit: 10 })).then((res) => {
      setSales(res.payload?.items || []);
    });
  }, [dispatch]);

  useEffect(() => {
    fetchSales(); // Initial fetch
  }, [fetchSales]);

  return (
    <>
      {loading && <ProgressSpinner isLoading={loading} />}
      <PageTitle pageTitle={SALES} />
      <Box mt={6} mb={6}></Box>
      <Sales salesItems={sales} />
    </>
  );
};

export default AllSales;
