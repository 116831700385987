import { useEffect, useRef, useState } from "react";
import { Grid, FormHelperText } from "@mui/material";
import TextField from "@mui/material/TextField";
import intlTelInput from "intl-tel-input";
import intlWithUtils from "intl-tel-input/build/js/intlTelInputWithUtils";
import "intl-tel-input/build/css/intlTelInput.css";
import { useController } from "react-hook-form";

const PhoneInputField = ({ className, value, error, onChange, setRules }) => {
  const inputRef = useRef(null);
  const itiRef = useRef(null); // Store intlTelInput instance
  const [placeholder, setPlaceholder] = useState("Enter phone number");
  const [placeHolderGap, setPlaceHolderGap] = useState(0);
  const isRequired = true;

  useEffect(() => {
    if (inputRef.current) {
      itiRef.current = intlTelInput(inputRef?.current, {
        initialCountry: "auto",
        separateDialCode: true,
        hiddenInput: (telInputName) => ({
          phone: "phoneNumber",
          country: "countryCode",
        }),
        geoIpLookup: (callback) => {
          fetch("https://ipapi.co/json/")
            .then((response) => response.json())
            .then((data) => {
              callback(data.country_code || "NO");
            })
            .catch(() => callback("NO")); // Default to Norway if detection fails
        },
        loadUtils: () => import("https://cdn.jsdelivr.net/npm/intl-tel-input@25.2.1/build/js/utils.js"),
      });

      const handlePlaceholderUpdate = () => {
        const countryContainer = inputRef.current.closest(".iti").querySelector(".iti__selected-dial-code");
        const dialCodeWidth = countryContainer ? countryContainer.offsetWidth : 0;

        setPlaceHolderGap(dialCodeWidth + 10);

        const countryData = itiRef.current.getSelectedCountryData();
        const numberType = intlWithUtils.utils.numberType["MOBILE"];
        const exampleNumber = intlWithUtils.utils.getExampleNumber(countryData.iso2, false, numberType).replace(`+${countryData.dialCode} `, "");
        setPlaceholder(exampleNumber);
      };

      const handleChange = () => {
        if (!itiRef.current) return;
        const rawValue = inputRef.current.value;
        const isValid = itiRef.current.isValidNumber();

        const formattedValue = isValid ? itiRef.current.getNumber() : rawValue;
        handlePlaceholderUpdate();

        onChange(formattedValue, isValid);
      };

      const handleCountryChange = () => {
        setRules({
          required: "validation.phoneNumber.required",
          validate: (value) => {
            const isValid = itiRef.current.isValidNumber();
            return isValid || "Invalid phone number";
          },
        });
      };

      itiRef.current.promise.then(() => {
        handlePlaceholderUpdate();
        handleCountryChange();
      });

      inputRef.current.addEventListener("countrychange", handleChange);
      inputRef.current.addEventListener("blur", handleChange);
      inputRef.current.addEventListener("input", handleChange);
      inputRef.current.addEventListener("countrychange", handleChange);

      handleCountryChange();
      handlePlaceholderUpdate();

      return () => {
        inputRef?.current?.removeEventListener("countrychange", handleChange);
        inputRef?.current?.removeEventListener("countrychange", handleCountryChange);
        inputRef?.current?.removeEventListener("blur", handleChange);
        // eslint-disable-next-line
        inputRef?.current?.removeEventListener("input", handleChange);
        itiRef.current?.destroy();
      };
    }
  }, [onChange, setRules]);

  useEffect(() => {
    if (itiRef.current && value) {
      itiRef.current.setNumber(value);
    }
  }, [value]);

  return (
    <TextField
      inputRef={inputRef}
      label={
        <span>
          {placeholder}
          {isRequired && <span style={{ color: "red" }}> *</span>}
        </span>
      }
      fullWidth
      margin="normal"
      color="secondary"
      onChange={(e) => onChange(e.target.value)}
      className={className}
      error={!!error}
      InputProps={{
        error: !!error,
        inputRef,
      }}
      FormHelperTextProps={{
        sx: {
          marginLeft: 0, // Removes default margin
        },
      }}
      InputLabelProps={{
        shrink: !!value,
        sx: {
          transform: `translate(${placeHolderGap + 42}px, 17px)`, // Default label position
          "&.MuiInputLabel-shrink": {
            transform: "translate(14px, -9px) scale(0.75)", // Default active position and scaling
            transformOrigin: "top left",
            lineHeight: "1.4375em",
          },
        },
      }}
      sx={{
        "& .iti__search-input": {
          padding: `16.5px 14px`,
          borderColor: "#510f5b",
        },
        "& .iti__search-input:focus": {
          outline: "none!important",
          border: "none!important",
          boxShadow: "none!important",
        },
        "& label": {
          color: "rgba(0, 0, 0, 0.4) !important",
        },
      }}
    />
  );
};

export const PhoneInput = ({ className, name, control, rules, sx, helperText, ...props }) => {
  const [dynamicRules, setDynamicRules] = useState(rules);
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, control, rules: dynamicRules });

  return (
    <Grid sx={sx}>
      <PhoneInputField error={error} value={value} onChange={onChange} setRules={setDynamicRules} className={className} />
      {error && helperText && (
        <FormHelperText error>
          <span>{helperText}</span>
        </FormHelperText>
      )}
    </Grid>
  );
};
