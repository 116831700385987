import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Uses localStorage
import rootReducer from "./rootReducer";
import { setupCartSync } from "../utils/cartSyncAcrossTabs";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "user", "cart", "product"], // Only persist auth and user state
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

const persistor = persistStore(store);

setupCartSync(store);

export { store, persistor };
