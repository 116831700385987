import { useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Box, Drawer, IconButton, useMediaQuery } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { PersonalMenus as tabs } from "../../../constants/PersonalMenus";
import { useSettingsDrawerContext } from "../../../context/SettingsDrawerContext";
import DrawerHeader from "./DrawerHeader";
import DrawerTabs from "./DrawerTabs";
import ContentDisplay from "./ContentDisplay";

export default function SettingsMenuDrawer() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { drawerOpen, closeDrawer, toggleDrawer, drawerRef } = useSettingsDrawerContext();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  const handleTabChange = (event, newValue) => {
    navigate(`/settings/${tabs[newValue]?.route}`);
    if (isMobile || isTablet) {
      closeDrawer();
    }
  };

  const activeTab = tabs.findIndex((tab) => location.pathname.includes(tab.route));

  return (
    <Box sx={{ display: "flex" }}>
      {/* Drawer */}
      <Drawer
        ref={drawerRef}
        variant="permanent"
        open={drawerOpen}
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerOpen ? 340 : 90,
            transition: "width 0.3s ease-in-out",
            backgroundColor: "#eff2f5",
            boxSizing: "border-box",
            borderRadius: "5px",
            marginTop: "140px",
            overflowY: "clip",
            "@media (max-width: 600px)": {
              width: drawerOpen ? 20 : 90,
              height: "100vh",
              padding: "10px",
              borderRadius: "0",
            },
            "@media (min-width: 601px) and (max-width: 960px)": {
              width: drawerOpen ? 20 : 90,
              height: "100vh",
              padding: "10px",
              borderRadius: "0",
            },
            "@media (min-width: 961px) and (max-width: 1280px)": {
              width: drawerOpen ? 300 : 90,
            },
            "@media (min-width: 1281px)": {
              width: drawerOpen ? 340 : 90,
            },
          },
        }}
      >
        <DrawerHeader>
          <IconButton
            onClick={toggleDrawer}
            sx={{
              position: "absolute",
              top: "5%",
              left: drawerOpen ? "calc(100% - 20px)" : "70px",
              transform: "translateY(-50%)",
              backgroundColor: "rgba(68, 153, 182, 1)",
              color: "white",
              borderRadius: "50%",
              zIndex: 3000,
              transition: "left 0.3s ease",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
              "&:hover": {
                backgroundColor: "#357ab8",
              },
              "@media (max-width: 600px)": {
                left: drawerOpen ? "0px" : "70px",
              },
              "@media (min-width: 601px) and (max-width: 960px)": {
                left: drawerOpen ? "0px" : "70px",
              },
            }}
          >
            {drawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <DrawerTabs tabs={tabs} activeTab={activeTab !== -1 ? activeTab : 0} handleTabChange={handleTabChange} drawerOpen={drawerOpen} />
      </Drawer>

      {/* Content Box */}
      <Box
        sx={{
          flexGrow: 1,
          padding: "20px",
          transition: "margin-left 0.3s ease",
          "@media (min-width: 1601px)": {
            marginLeft: drawerOpen ? "20px !important" : "20px",
          },
          "@media (min-width: 1441px) and (max-width: 1600px)": {
            marginLeft: drawerOpen ? "180px !important" : "20px",
          },
          "@media (min-width: 1281px) and (max-width: 1440px)": {
            marginLeft: drawerOpen ? "280px" : "90px",
          },
          "@media (min-width: 961px) and (max-width: 1280px)": {
            marginLeft: drawerOpen ? "280px" : "90px",
          },
          "@media (min-width: 601px) and (max-width: 960px)": {
            marginLeft: drawerOpen ? "40px" : "90px",
          },
          "@media (max-width: 600px)": {
            width: "100%",
            marginLeft: drawerOpen ? "40px" : "90px",
            padding: "0",
            marginTop: "2rem",
          },
          "@media (max-width: 480px)": {
            padding: "10px",
          },
        }}
      >
        <ContentDisplay tabs={tabs} activeTab={activeTab} />
      </Box>
    </Box>
  );
}
