import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URLS } from "../../utils/apiConstants";
import apiClient from "../../utils/axiosInstance";

// Helper function for API requests
const apiRequest = async (method, url, data, headers, rejectWithValue) => {
  try {
    const response = await apiClient({
      method,
      url,
      data,
      headers,
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
};

// Helper function for extracting product id
const getProductId = (getState) => {
  const productState = getState().product;
  const prodId = productState?.productId;

  if (!prodId) {
    throw new Error("Product ID is missing");
  }

  return prodId;
};

// Creating a product : Listing toy
export const saveProduct = createAsyncThunk("product/saveProduct", (productData, { rejectWithValue }) =>
  apiRequest("post", API_URLS.SAVE_PRODUCT, productData, rejectWithValue)
);

// Saving a product media : Listing toy
export const saveProductMedia = createAsyncThunk("product/saveProductMedia", async (productMedia, { getState, rejectWithValue }) => {
  const prodId = getProductId(getState);

  return await apiRequest(
    "post",
    `${API_URLS.SAVE_PRODUCT_MEDIA.replace("{id}", prodId)}`,
    productMedia,
    {
      "Content-Type": "multipart/form-data",
    },
    rejectWithValue
  );
});

// Publishing toy : Listing toy
export const updateProduct = createAsyncThunk("product/updateProduct", async ({ productId, payload }, { getState, rejectWithValue }) => {
  const prodId = productId ? productId : getProductId(getState);

  if (!payload) {
    throw new Error("Payload is required to update the product.");
  }
  return await apiRequest("patch", `${API_URLS.UPDATE_PRODUCT.replace("{id}", prodId)}`, payload, rejectWithValue);
});

// Fetching specific product details
export const getProductDetails = createAsyncThunk("product/getProductDetails", ({ productId }, { rejectWithValue }) =>
  apiRequest("get", `${API_URLS.GET_PRODUCT_DETAILS.replace("{id}", productId)}`, null, rejectWithValue)
);

// Delete Product
export const deleteProduct = createAsyncThunk("product/deleteProduct", ({ productId }, { rejectWithValue }) =>
  apiRequest("delete", `${API_URLS.DELETE_PRODUCT.replace("{id}", productId)}`, null, rejectWithValue)
);

// Delete Product Media
export const deleteProductMedia = createAsyncThunk("product/deleteProductMedia", async ({ mediaUids }, { getState, rejectWithValue }) => {
  const prodId = getProductId(getState);
  return await apiRequest("delete", `${API_URLS.DELETE_PRODUCT_MEDIA.replace("{id}", prodId)}`, { mediaUids }, rejectWithValue);
});

const _fetchProducts = async ({ my, isPublished, limit = 10, lastVisible, q }, { rejectWithValue }) => {
  const params = {
    my,
    isPublished,
    limit,
    lastVisible,
  };

  if (q) {
    params.q = q;
  }

  // Filter out undefined parameters to avoid unnecessary query strings
  const filteredParams = Object.fromEntries(Object.entries(params).filter(([_, value]) => value !== undefined));

  // Use URLSearchParams to construct the query string
  const queryString = new URLSearchParams(filteredParams).toString();

  // Build the full API URL with query parameters
  const url = `${API_URLS.GET_ALL_PRODUCTS}?${queryString}`;

  // Make the API request with the constructed URL
  return await apiRequest("get", url, rejectWithValue);
};

// Fetching all products: All listings (published/ unpublished)
export const fetchAllProducts = createAsyncThunk(
  "product/fetchAllProducts",
  _fetchProducts,
);
// Fetching all products: All listings (published/ unpublished)
export const fetchMoreProducts = createAsyncThunk(
  "product/fetchMoreProducts",
  _fetchProducts,
);

// Product slice
const productSlice = createSlice({
  name: "product",
  initialState: {
    productList: [],
    productData: null,
    productId: null,
    lastVisible: null,
    totalItems: 0,
    loading: false,
    error: null,
  },
  reducers: {
    resetProductState: (state) => {
      state.productData = null;
      state.productId = null;
      state.loading = false;
      state.error = null;
      state.productList = [];
      state.lastVisible = null;
    },
  },
  extraReducers: (builder) => {
    const handlePending = (state) => {
      state.loading = true;
      state.error = null;
    };

    const handleFulfilled = (state, action) => {
      state.loading = false;
      state.productData = {
        ...state.productData,
        ...action.payload,
      };
      state.productId = action.payload?.uid;
      if (action.payload?.items) {
        state.productList = action.payload.items;
        state.lastVisible = action.payload.meta?.lastVisible || null;
        state.totalItems = action.payload.meta?.totalItems || 0;
      }
    };

    const handleFetchMoreFulfilled = (state, action) => {
      state.loading = false;
      state.productData = {
        ...state.productData,
        ...action.payload,
      };

      if (action.payload?.items) {
        state.productList = [...state.productList, ...action.payload.items];
        state.lastVisible = action.payload.meta?.lastVisible || null;
        state.totalItems = action.payload.meta?.totalItems || 0;
      }
    };

    const handleRejected = (state, action) => {
      state.loading = false;
      state.error = action.payload;
    };

    const handleUpdateFulfilled = (state, action) => {
      state.loading = false;
      const response = action.meta?.response || action.payload;
      if (response) {
        state.productData = {
          ...state.productData,
          ...response,
        };
        state.productId = response?.uid;
      }
    };

    builder
      .addCase(saveProduct.pending, handlePending)
      .addCase(saveProduct.fulfilled, handleFulfilled)
      .addCase(saveProduct.rejected, handleRejected)
      .addCase(saveProductMedia.pending, handlePending)
      .addCase(saveProductMedia.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload?.media) {
          state.productData = {
            ...state.productData,
            media: action.payload.media, // Only update media property
          };
        }
      })
      .addCase(saveProductMedia.rejected, handleRejected)
      .addCase(updateProduct.pending, handlePending)
      .addCase(updateProduct.fulfilled, handleUpdateFulfilled)
      .addCase(updateProduct.rejected, handleRejected)
      .addCase(getProductDetails.pending, handlePending)
      .addCase(getProductDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.productId = action.payload?.uid; // Update productId
        state.productData = action.payload;
      })
      .addCase(getProductDetails.rejected, handleRejected)
      .addCase(fetchAllProducts.pending, handlePending)
      .addCase(fetchAllProducts.fulfilled, handleFulfilled)
      .addCase(fetchMoreProducts.fulfilled, handleFetchMoreFulfilled)
      .addCase(fetchAllProducts.rejected, handleRejected)
      .addCase(deleteProductMedia.pending, handlePending)
      .addCase(deleteProductMedia.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload?.media) {
          state.productData = {
            ...state.productData,
            media: action.payload.media, // Only update media property
          };
        }
      })
      .addCase(deleteProductMedia.rejected, handleRejected);
  },
});

export default productSlice.reducer;
