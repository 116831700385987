import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URLS } from "../../utils/apiConstants";
import apiClient from "../../utils/axiosInstance";

// Helper function for API requests
const apiRequest = async (method, url, data, headers, rejectWithValue) => {
  try {
    const response = await apiClient({ method, url, data, headers });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response ? error.response.data : error.message);
  }
};

// Generic function for fetching orders (Purchases/Sales)
const fetchOrders = async ({ type, state, limit = 10, lastVisible }, { rejectWithValue }) => {
  const params = { state, limit, lastVisible };
  const filteredParams = Object.fromEntries(Object.entries(params).filter(([_, value]) => value !== undefined));
  const queryString = new URLSearchParams(filteredParams).toString();

  const url = type === "purchases" ? `${API_URLS.GET_PURCHASES}?${queryString}` : `${API_URLS.GET_SALES}?${queryString}`;
  return await apiRequest("get", url, null, null, rejectWithValue);
};

// Async thunks using the generic function
export const getPurchases = createAsyncThunk("order/getPurchases", (args, thunkAPI) => fetchOrders({ ...args, type: "purchases" }, thunkAPI));
export const getSales = createAsyncThunk("order/getSales", (args, thunkAPI) => fetchOrders({ ...args, type: "sales" }, thunkAPI));

// Initial state
const initialState = {
  orderId: null,
  lastVisible: null,
  totalItems: 0,
  productList: [],
  loading: false,
  error: null,
};

// Slice
const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    resetOrderState: () => initialState,
  },
  extraReducers: (builder) => {
    const handlePending = (state) => {
      state.loading = true;
      state.error = null;
    };

    const handleFulfilled = (state, action) => {
      state.loading = false;
      state.productList = action.payload?.items || [];
      state.lastVisible = action.payload?.meta?.lastVisible || null;
      state.totalItems = action.payload?.meta?.totalItems || 0;
    };

    const handleRejected = (state, action) => {
      state.loading = false;
      state.error = action.payload;
    };

    builder
      .addCase(getPurchases.pending, handlePending)
      .addCase(getPurchases.fulfilled, handleFulfilled)
      .addCase(getPurchases.rejected, handleRejected)
      .addCase(getSales.pending, handlePending)
      .addCase(getSales.fulfilled, handleFulfilled)
      .addCase(getSales.rejected, handleRejected);
  },
});

export const { resetOrderState } = orderSlice.actions;
export default orderSlice.reducer;
