import { Select, MenuItem, InputLabel, FormControl, OutlinedInput, Box, Typography } from "@mui/material";
import { useController } from "react-hook-form";
import SelectedChip from "./SelectedChip";
import { menuItemStyles } from "./MenuItemStyles";

const SelectComponent = ({
  name,
  control,
  label,
  options,
  getOptionLabel,
  getOptionValue,
  multiple,
  helperText, // Static helper text
  helpertextmap, // Dynamic helper text map
  sx,
  ...rest
}) => {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({ name, control, rules: rest.rules });

  // Ensure the selected option exists
  const selectedOption = options.find((option) => getOptionValue(option) === value);

  // Check if helpertextmap is provided and get the dynamic helper text for the selected option
  const dynamicHelperText = helpertextmap && selectedOption ? helpertextmap[selectedOption.value]?.helperText || "" : "";
  const dynamicSubHelperText = helpertextmap && selectedOption ? helpertextmap[selectedOption.value]?.subHelperText || "" : "";
  const displayHelperText = dynamicHelperText || helperText; // Decide which helperText to show: static or dynamic
  const displaySubHelperText = dynamicSubHelperText;

  // Handle change event
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    onChange(selectedValue);
  };

  // Function to handle removal of selected chips
  const handleChipDelete = (e, chipValue) => {
    e.preventDefault();
    const newValue = value.filter((val) => val !== chipValue);
    onChange(newValue);
  };

  // Check if the field is required
  const isRequired = rest.rules?.required;

  return (
    <FormControl fullWidth margin="normal" sx={sx} error={!!error}>
      <InputLabel id={`select-label-${name}`} color="secondary">
        {label} {isRequired && <span style={{ color: "red" }}>*</span>}
      </InputLabel>
      <Select
        multiple={multiple}
        onChange={handleChange}
        onBlur={onBlur}
        inputRef={ref}
        labelId={`select-label-${name}`}
        color="secondary"
        value={value || (multiple ? [] : "")} // Ensure default value is set correctly
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => {
          if (multiple) {
            // Render selected values as Chips
            return (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((val) => {
                  const label = getOptionLabel(options.find((option) => getOptionValue(option) === val));
                  return <SelectedChip key={val} label={label} onDelete={(e) => handleChipDelete(e, val)} />;
                })}
              </Box>
            );
          } else {
            // For single select, display as regular text
            const label = getOptionLabel(options.find((option) => getOptionValue(option) === selected));
            return label;
          }
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: 300, // Set the height for the dropdown
              overflowY: "scroll !important", // Enable vertical scrolling
              "&::-webkit-scrollbar": {
                display: "block",
                width: "8px",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0, 0, 0, 0.3)",
                borderRadius: "4px",
              },
              "&::-webkit-scrollbar-track": {
                background: "transparent",
              },
            },
          },
        }}
      >
        {options.map((option) => {
          const optionValue = getOptionValue(option);
          return (
            <MenuItem key={optionValue} value={optionValue} sx={menuItemStyles}>
              {getOptionLabel(option)}
            </MenuItem>
          );
        })}
      </Select>

      {/* Conditionally render helperText and subHelperText using Typography */}
      {(displayHelperText || displaySubHelperText) && (
        <Box sx={{ mt: 1 }}>
          {displayHelperText && (
            <Typography mt={1} variant="body2" color={error ? "error" : "textSecondary"}>
              {displayHelperText}
            </Typography>
          )}
          {displaySubHelperText && (
            <Typography variant="caption" color="textSecondary">
              {displaySubHelperText}
            </Typography>
          )}
        </Box>
      )}
    </FormControl>
  );
};

export default SelectComponent;
