import { Box, Divider } from "@mui/material";
import { StyledTabs, StyledTab } from "./SettingsMenuDrawer.styled";

export default function DrawerTabs({ tabs, activeTab, handleTabChange, drawerOpen }) {
  return (
    <>
      <StyledTabs orientation="vertical" value={activeTab} onChange={handleTabChange}>
        {tabs.map((tab, index) => (
          <StyledTab
            key={index}
            label={
              drawerOpen ? (
                // Show both icon and label when drawer is open
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "12px", // Adjust the gap between icon and text
                    width: "100%", // Ensure consistent width
                    justifyContent: drawerOpen ? "flex-start" : "center", // Align text when open
                  }}
                >
                  {tab.icon} {/* Assuming each tab has an icon */}
                  <span>{tab.label}</span>
                </Box>
              ) : (
                // Show only the icon when drawer is collapsed
                tab.icon
              )
            }
          />
        ))}
      </StyledTabs>
      <Divider />
    </>
  );
}
