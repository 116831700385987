import { Button } from "@mui/material";
import { styled } from "@mui/system";

const ContainedButton = styled(Button)(({ theme, customStyle }) => ({
  backgroundColor: "#ffffff",
  border: "1px solid #510f5b",
  color: "#510f5b",
  padding: "8px 26px",
  borderRadius: "25px",
  "&:hover": {
    backgroundColor: "#510f5b",
    color: "#ffffff",
  },
  "&:disabled": {
    backgroundColor: "#ffffff",
    border: "1px solid #cccccc",
    color: "#cccccc",
    cursor: "not-allowed",
  },
  textTransform: "none",
  fontWeight: "500",
  fontSize: "0.875rem",
  "@media (max-width:600px)": {
    padding: "4px 16px", // Custom padding for small devices (<= 600px)
  },
  ...customStyle, // Merge any additional custom styles
}));

export default ContainedButton;
