import { Tooltip, Typography } from "@mui/material";

const CustomTooltip = ({ title, children, placement = "top", arrow = true }) => {
  return (
    <Tooltip title={<Typography variant="body2">{title}</Typography>} placement={placement} arrow={arrow}>
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
