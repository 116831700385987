export const BASE_URL = process.env.REACT_APP_BASE_API_URL || "http://localhost:5555/api/v1";

// full API URLs using the BASE_URL
export const API_URLS = {
  REGISTER: `${BASE_URL}/register`,
  ME: `${BASE_URL}/me`,
  RESEND_OTP: `${BASE_URL}/resend-otp`,
  VERIFY_OTP: `${BASE_URL}/verify-otp`,
  ADD_CHILDREN: `${BASE_URL}/users/{id}/children`,
  UPDATE_CHILDREN: `${BASE_URL}/update-children`,
  DELETE_CHILD: `${BASE_URL}/children/{childUid}`,
  SAVE_ADDRESS: `${BASE_URL}/update-delivery-address`,
  SAVE_PRODUCT: `${BASE_URL}/products`,
  SAVE_PRODUCT_MEDIA: `${BASE_URL}/products/{id}/media`,
  UPDATE_PRODUCT: `${BASE_URL}/products/{id}`,
  GET_PRODUCT_DETAILS: `${BASE_URL}/products/{id}`,
  DELETE_PRODUCT: `${BASE_URL}/products/{id}`,
  DELETE_PRODUCT_MEDIA: `${BASE_URL}/products/{id}/media`,
  GET_ALL_PRODUCTS: `${BASE_URL}/products`,
  UPDATE_PROFILE: `${BASE_URL}/update-profile`,
  ADD_CHILD_PHOTO: `${BASE_URL}/children/{childUid}/photo`,
  DELETE_CHILD_PHOTO: `${BASE_URL}/children/{childUid}/photo`,
  ADD_TO_CART: `${BASE_URL}/add-to-shopping-cart`,
  REMOVE_FROM_CART: `${BASE_URL}/remove-from-shopping-cart`,
  CLEAR_CART: `${BASE_URL}/clear-shopping-cart`,
  GET_PURCHASES: `${BASE_URL}/purchases`,
  GET_SALES: `${BASE_URL}/sales`,
  GET_PURCHASE_DETAILS: `${BASE_URL}/purchases/{orderUid}`,
  CHECKOUT: `${BASE_URL}/checkout`,
};
