import { Container, Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { useLanguageContext } from "../../../context/LanguageContext";
import TextFieldComponent from "./TextFieldComponent";
import CheckboxComponent from "./CheckboxComponent";
import SelectComponent from "./SelectComponent";
import DatePickerComponent from "./DatePickerComponent";
import classes from "./TemplateForm.module.scss";
import { PhoneInput } from "./PhoneNumber";

const getLabel = (labelKey, translations) => {
  const label = translations.fields[labelKey.split(".").pop()];
  return label ? label : labelKey; // Fallback to the key itself if not found
};

const getErrorMessage = (field, errors, validationMessages) => {
  if (errors[field.name]) {
    const errorType = errors[field.name].type;
    // Access the correct message based on the error type
    return validationMessages[field.name]?.[errorType] || "";
  }
  return "";
};

const getHelperText = (helperTextKey, helperTexts) => {
  if (typeof helperTextKey === "function") {
    return helperTextKey(); // Execute the function if helperText is dynamic
  }
  return helperTextKey ? helperTexts[helperTextKey.split(".").pop()] : "";
};

const renderField = (field, control, errors, translations) => {
  const { validation: validationMessages, helperText: helperTexts } = translations;

  const label = getLabel(field.label, translations);
  const errorMessage = getErrorMessage(field, errors, validationMessages);
  const helperText = getHelperText(field.helperText, helperTexts);

  const { name, type, options, getOptionLabel, getOptionValue, onChange, helpertextmap, multiline, rows, calculateHelperText } = field;

  const commonProps = {
    name,
    control,
    helperText: errorMessage || helperText,
    helpertextmap: helpertextmap,
    onChange,
    multiline,
    rows,
    calculateHelperText,
    rules: field.validation || {}, //comment this for bypassing the validations on the form
  };

  switch (field.type) {
    case "text":
    case "email":
    case "password":
    case "number":
      return <TextFieldComponent key={name} label={label} type={type} className={classes.inputField} {...commonProps} />;
    case "phoneNumber":
      return <PhoneInput 
        key={name} 
        label={label} 
        className={classes.inputField}
        {...commonProps} 
      />
    case "select":
    case "multiselect":
      return (
        <SelectComponent
          key={name}
          label={label}
          options={options}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          multiple={type === "multiselect"} // Conditionally set multiple
          {...commonProps}
        />
      );

    case "checkbox":
      return <CheckboxComponent key={name} label={label} options={options} {...commonProps} />;

    case "date":
      return <DatePickerComponent key={field.name} label={label} defaultDate={new Date()} {...commonProps} />;

    default:
      return null;
  }
};

const TemplateForm = ({ fields, translationKey }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { t } = useLanguageContext();
  const translations = t(translationKey, { returnObjects: true });

  return (
    <Container maxWidth="md">
      <Grid container>
        {fields.map((field) => (
          <Grid
            item
            xs={12} // Full width on small screens
            md={field.width ? parseInt(field.width) / 10 : 12} // Convert width percentage to Grid size
            key={field.name}
            marginRight={1}
          >
            {renderField(field, control, errors, translations)}
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default TemplateForm;
