import { Box, Container } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo } from "react";

import { editPersonalInfoFields } from "../../../constants/EditInfoFields";
import { REGISTRATION } from "../../../constants/NavbarConstants";
import { fetchUserProfile, selectUserProfile, updateProfile } from "../../../redux/slices/authSlice";

import TemplateForm from "../../../components/common/forms/TemplateForm";
import PageSubTitle from "../corelist/PageSubTitle";
import ContainedButton from "../../../components/common/layout/ContainedButton";

import usePhoneNumParser from "../../../hooks/usePhoneNumParser";
import "../corelist/ListView.scss";
import { useSnackbar } from "../../../context/SnackbarProvider";

const UpdatePersonalInfo = () => {
  const dispatch = useDispatch();
  const userProfile = useSelector(selectUserProfile);
  const { parsedPhone, parsePhoneNumber } = usePhoneNumParser();
  const { showSnackbar } = useSnackbar();

  // Memoize defaultValues to prevent re-creation on each render
  const defaultValues = useMemo(
    () => ({
      firstName: userProfile.firstName || "",
      lastName: userProfile.lastName || "",
      dob: userProfile.dob || "",
      phoneNumber: userProfile.phoneNumber || "",
      address: userProfile.deliveryAddress?.address || "",
      zip: userProfile.deliveryAddress?.zip || "",
      city: userProfile.deliveryAddress?.city || "",
      country: userProfile.deliveryAddress?.country || "",
      countryCode: "",
    }),
    [userProfile]
  );

  const methods = useForm({ defaultValues });
  const { reset } = methods;

  // Parse the phone number when the component mounts or the user profile changes
  useEffect(() => {
    if (userProfile.phoneNumber) {
      parsePhoneNumber(userProfile.phoneNumber);
    }
  }, [userProfile.phoneNumber, parsePhoneNumber]);

  // Update the form values once the phone number has been parsed
  useEffect(() => {
    if (parsedPhone) {
      reset({
        ...defaultValues,
        phoneNumber: parsedPhone.nationalNumber,
        countryCode: `+${parsedPhone.countryCallingCode}`,
      });
    }
  }, [parsedPhone, reset, defaultValues]);

  const onSubmit = async (data) => {
    try {
      const { countryCode, phoneNumber, ...filteredData } = data;
      // const fullPhoneNumber = `${countryCode || ""}${phoneNumber || ""}`; // Combine phone number
      const updatedData = { ...filteredData, phoneNumber: phoneNumber };
      const updateResponse = await dispatch(updateProfile(updatedData));
      if (!updateResponse.error) {
        dispatch(fetchUserProfile());
        showSnackbar("User personal information updated successfully!", "success");
      } else {
        showSnackbar("Failed to update children. Please try again.", "error");
      }
    } catch (error) {
      showSnackbar("An unexpected error occurred.", "error");
      throw error;
    }
  };

  return (
    <>
      <PageSubTitle pageSubTitle={"personalInfo"} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <TemplateForm fields={editPersonalInfoFields} translationKey={REGISTRATION} />
          <Container maxWidth="md">
            <Box my={3} className="list-toy-btn">
              <ContainedButton type="submit">Save and update</ContainedButton>
            </Box>
          </Container>
        </form>
      </FormProvider>
    </>
  );
};

export default UpdatePersonalInfo;
