import { Box, Typography } from "@mui/material";

const PrivacyPolicySection = ({ title, content, isBulletList = false, contentVariant = "h5" }) => {
  // Function to split content by '/n' and render each part in a new line
  const renderContent = (content) => {
    // Check if content includes a newline character
    if (typeof content === "string" && content.includes("/n")) {
      const lines = content.split("/n");
      return lines.map((line, index) => (
        <Typography key={index} variant="body1" paragraph>
          {line}
        </Typography>
      ));
    }
    // If no newline character, return content as it is
    return (
      <Typography variant="body1" paragraph>
        {content}
      </Typography>
    );
  };

  return (
    <Box sx={{ mt: 3 }}>
      <ul
        style={{
          listStyleType: "none", // Remove bullets
          paddingLeft: 0, // Remove default padding
        }}
      >
        <li>
          <Typography
            variant={contentVariant}
            fontWeight="400"
            gutterBottom
            sx={{ color: "#510f5b" }} // Set custom color for headers
          >
            {title}
          </Typography>
        </li>
      </ul>
      {isBulletList ? (
        <ul
          style={{
            listStyleType: "none", // Remove bullets
            paddingLeft: 0, // Remove padding
            margin: 0, // Remove margin
          }}
        >
          {content.map((item, index) => (
            <li key={index} style={{ marginBottom: "10px" }}>
              <Typography variant="body1" paragraph>
                {item}
              </Typography>
            </li>
          ))}
        </ul>
      ) : (
        renderContent(content) // Call renderContent to handle new lines
      )}
    </Box>
  );
};

export default PrivacyPolicySection;
