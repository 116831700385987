import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URLS } from "../../utils/apiConstants";
import apiClient from "../../utils/axiosInstance";
import { auth } from "../../config/firebase";

// Helper function for error handling
const handleError = (error) => (error.response ? error.response.data : error.message);

// Async thunks
export const registerUser = createAsyncThunk("registration/registerUser", async (userData, { rejectWithValue }) => {
  try {
    const response = await apiClient.post(API_URLS.REGISTER, userData);
    return response.data;
  } catch (error) {
    return rejectWithValue(handleError(error));
  }
});

export const resendOtp = createAsyncThunk("registration/resendOtp", async (_, { rejectWithValue }) => {
  try {
    const response = await apiClient.post(API_URLS.RESEND_OTP, {});
    await auth.currentUser.reload();
    return response.data;
  } catch (error) {
    return rejectWithValue(handleError(error));
  }
});

export const verifyOtp = createAsyncThunk("registration/verifyOtp", async (otpPayload, { rejectWithValue }) => {
  try {
    const response = await apiClient.post(API_URLS.VERIFY_OTP, otpPayload); // Ensure it's wrapped

    // If the response is successful, reauthenticate the user
    if (response.status === 200) {
      await auth.currentUser.reload();
    }

    return response.data;
  } catch (error) {
    return rejectWithValue(handleError(error));
  }
});

export const saveSubUsers = createAsyncThunk("registration/saveSubUsers", async (childrenData, { getState, rejectWithValue }) => {
  const state = getState();
  const userId = state.auth.profile.uid; // Fetch uid from auth slice

  if (!userId) {
    throw new Error("User ID is missing");
  }
  try {
    const response = await apiClient.post(`${API_URLS.ADD_CHILDREN.replace("{id}", userId)}`, childrenData);
    return response.data;
  } catch (error) {
    return rejectWithValue(handleError(error));
  }
});

export const updateChildren = createAsyncThunk("registration/updateChildren", async (childrenData, { rejectWithValue }) => {
  try {
    const response = await apiClient.post(`${API_URLS.UPDATE_CHILDREN}`, childrenData);
    return response.data;
  } catch (error) {
    return rejectWithValue(handleError(error));
  }
});

export const deleteChild = createAsyncThunk("registration/deleteChild", async (childUid, { rejectWithValue }) => {
  try {
    const response = await apiClient.delete(`${API_URLS.DELETE_CHILD.replace("{childUid}", childUid)}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(handleError(error));
  }
});

export const addChildPhoto = createAsyncThunk("registration/addChildPhoto", async ({ childUid, childPhoto }, { rejectWithValue }) => {
  try {
    const response = await apiClient.post(`${API_URLS.ADD_CHILD_PHOTO.replace("{childUid}", childUid)}`, childPhoto);
    return response.data;
  } catch (error) {
    return rejectWithValue(handleError(error));
  }
});

export const removeChildPhoto = createAsyncThunk("registration/removeChildPhoto", async (childUid, { rejectWithValue }) => {
  try {
    const response = await apiClient.delete(`${API_URLS.ADD_CHILD_PHOTO.replace("{childUid}", childUid)}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(handleError(error));
  }
});

export const saveAddress = createAsyncThunk("registration/saveAddress", async (addressPayload, { rejectWithValue }) => {
  try {
    const response = await apiClient.post(`${API_URLS.SAVE_ADDRESS}`, addressPayload);
    return response.data;
  } catch (error) {
    return rejectWithValue(handleError(error));
  }
});

// Initial state
const initialState = {
  userData: null,
  isActive: false,
  childrenData: null,
  addressData: null,
  loading: false,
  error: null,
};

// Registration slice
const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    const handlePending = (state) => {
      state.loading = true;
      state.error = null;
    };

    const handleRejected = (state, action) => {
      state.loading = false;
      state.error = action.payload?.errors || [{ field: "unknown", message: action.payload?.message || "An error occurred." }];
    };

    builder
      // Register User
      .addCase(registerUser.pending, handlePending)
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.userData = action.payload;
      })
      .addCase(registerUser.rejected, handleRejected)

      // Verify OTP
      .addCase(verifyOtp.pending, handlePending)
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.loading = false;
        state.isActive = action.payload;
      })
      .addCase(verifyOtp.rejected, handleRejected)

      // Save Children
      .addCase(saveSubUsers.pending, handlePending)
      .addCase(saveSubUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.childrenData = action.payload;
      })
      .addCase(saveSubUsers.rejected, handleRejected)

      // Save Address
      .addCase(saveAddress.pending, handlePending)
      .addCase(saveAddress.fulfilled, (state, action) => {
        state.loading = false;
        state.addressData = action.payload;
      })
      .addCase(saveAddress.rejected, handleRejected)
      // Add child's photo
      .addCase(addChildPhoto.pending, handlePending)
      .addCase(addChildPhoto.fulfilled, (state, action) => {
        state.loading = false;
        state.childrenData = action.payload;
      })
      // update children
      .addCase(updateChildren.pending, handlePending)
      .addCase(updateChildren.fulfilled, (state, action) => {
        state.loading = false;
        state.childrenData = action.payload;
      })
      .addCase(updateChildren.rejected, handleRejected)
      // Remove child's photo
      .addCase(removeChildPhoto.pending, handlePending)
      .addCase(removeChildPhoto.fulfilled, (state, action) => {
        state.loading = false;
        state.childrenData = action.payload;
      })
      .addCase(removeChildPhoto.rejected, handleRejected)
      // delete child data
      .addCase(deleteChild.pending, handlePending)
      .addCase(deleteChild.fulfilled, (state, action) => {
        state.loading = false;
        state.childrenData = action.payload;
      })
      .addCase(deleteChild.rejected, handleRejected);
  },
});

export default registrationSlice.reducer;
