import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CancelScheduleSendIcon from "@mui/icons-material/CancelScheduleSend";
import "./ListView.scss";
import CustomTooltip from "../../../components/common/forms/CustomTooltip";

const ActionIcons = ({ onSend, onDelete, onEdit, onRelist }) => {
  const handleClick = (e, action) => {
    e.stopPropagation();
    e.preventDefault(); // Prevents any unintended behavior
    action();
  };

  return (
    <>
      {onSend && (
        <CustomTooltip title="Unpublish the listing">
          <IconButton className="icon-btn" onMouseDown={(e) => handleClick(e, onSend)}>
            <CancelScheduleSendIcon />
          </IconButton>
        </CustomTooltip>
      )}

      {onDelete && (
        <CustomTooltip title="Delete the listing">
          <IconButton className="icon-btn" onMouseDown={(e) => handleClick(e, onDelete)}>
            <DeleteIcon />
          </IconButton>
        </CustomTooltip>
      )}

      {onEdit && (
        <CustomTooltip title="Edit the listing">
          <IconButton className="icon-btn" onMouseDown={(e) => handleClick(e, onEdit)}>
            <EditIcon />
          </IconButton>
        </CustomTooltip>
      )}

      {onRelist && (
        <CustomTooltip title="Re-list the product">
          <IconButton className="icon-btn" onMouseDown={(e) => handleClick(e, onRelist)}>
            <EditIcon />
          </IconButton>
        </CustomTooltip>
      )}
    </>
  );
};

export default ActionIcons;
