import { Chip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import classes from "./TemplateForm.module.scss";

const SelectedChip = ({ label, onDelete }) => {
  return (
    <Chip
      label={label}
      onDelete={onDelete}
      deleteIcon={<CloseIcon onMouseDown={(event) => event.stopPropagation()} className={classes.chipDeleteIcon} />}
    />
  );
};

export default SelectedChip;
