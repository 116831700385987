import { Box, Typography, Button, Dialog, DialogContent } from "@mui/material";
import "./ListView.scss";

const ActionConfirmPopup = ({ config }) => {
  const { open, title, description, onConfirm, onCancel, confirmLabel = "Yes", cancelLabel = "No" } = config;

  return (
    <Dialog open={open} onClose={onCancel} disableEnforceFocus>
      <DialogContent className="dialog-content">
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
        <Typography variant="body1" className="dialog-description">
          {description}
        </Typography>
        <Box className="dialog-action-box">
          <Button onClick={onCancel} className="dialog-btns">
            {cancelLabel}
          </Button>
          <Button onClick={onConfirm} className="dialog-btns">
            {confirmLabel}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ActionConfirmPopup;
