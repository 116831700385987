import styled from "styled-components";
import { Box, Drawer, Tabs, Tab } from "@mui/material";

// Wrapper for the entire drawer and content layout
export const DrawerWrapper = styled(Box)`
  display: flex;

  /* Stack elements vertically for smaller devices */
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const StyledDrawer = styled(Drawer).withConfig({
  shouldForwardProp: (prop) => prop !== "drawerOpen",
})(({ drawerOpen }) => {
  return {
    "& .MuiDrawer-paper": {
      width: drawerOpen ? "340px" : "90px", // Adjust width for mini variant style
      transition: "width 0.3s ease-in-out",
      backgroundColor: "#eff2f5",
      boxSizing: "border-box",
      borderRadius: "5px",
      marginTop: "140px",
      overflowY: "clip !important",

      /* Smaller Devices - Mobile (up to 600px) */
      "@media (max-width: 600px)": {
        width: drawerOpen ? "20px" : "90px",
        height: "100vh",
        marginTop: "140px",
        padding: "10px",
        borderRadius: "0",
      },

      /* Medium Devices - Tablet (600px to 960px) */
      "@media (min-width: 601px) and (max-width: 960px)": {
        width: drawerOpen ? "200px" : "90px", // Adjust for tablets
      },

      /* Small Desktops (960px to 1280px) */
      "@media (min-width: 961px) and (max-width: 1280px)": {
        width: drawerOpen ? "300px" : "90px",
      },

      /* Larger Desktops (1280px and above) */
      "@media (min-width: 1281px)": {
        width: drawerOpen ? "340px" : "90px",
      },
    },
  };
});

// Content Box for the main content area
export const ContentBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "drawerOpen",
})(({ drawerOpen }) => ({
  flexGrow: 1,
  padding: "20px",
  transition: "margin-left 0.3s ease",

  // Extra Large Screens (Above 1600px)
  "@media (min-width: 1601px)": {
    marginLeft: drawerOpen ? "20px !important" : "20px",
  },

  // Large Screens (Above 1280px)
  "@media (min-width: 1441px) and (max-width: 1600px)": {
    marginLeft: drawerOpen ? "180px !important" : "20px",
  },

  "@media (min-width: 1281px) and (max-width: 1440px)": {
    marginLeft: drawerOpen ? "280px" : "90px", // No margin for larger screens
  },

  "@media (min-width: 961px) and (max-width: 1280px)": {
    marginLeft: drawerOpen ? "280px" : "90px", // Adjust for small desktops
  },
  // Default margin for larger screens
  "@media (min-width: 601px) and (max-width: 960px)": {
    marginLeft: drawerOpen ? "200px" : "90px", // Adjust based on drawer state
  },

  // For small devices - up to 600px
  "@media (max-width: 600px)": {
    width: "100%",
    marginLeft: drawerOpen ? "40px" : "90px", // Adjust based on drawer state
    padding: "0", // Adjust padding for small devices
    marginTop: "2rem",
  },

  // For very small devices - up to 480px
  "@media (max-width: 480px)": {
    padding: "10px", // Padding for very small devices
  },
}));

// Styled Tabs
export const StyledTabs = styled(Tabs)`
  border-right: 1px solid var(--divider-color);

  .MuiTabs-flexContainer {
    gap: 10px; /* Space between tabs */
  }

  .MuiTab-root {
    text-transform: none;
    justify-content: flex-start;
    align-items: baseline;
    padding: 20px 30px;
    border-radius: 10px;
    color: #161d1c;
    font-size: 16px;
    font-weight: 400;
  }

  .Mui-selected {
    background-color: rgba(163, 205, 219, 1);
    color: #161d1c;
    font-weight: 500;
    border-radius: 50px;
  }

  /* Small Devices (up to 600px) */
  @media (max-width: 600px) {
    .MuiTab-root {
      padding: 10px 15px; /* Reduce padding for small devices */
      font-size: 14px; /* Adjust font size */
    }
  }

  /* Very Small Devices (up to 480px) */
  @media (max-width: 480px) {
    .MuiTab-root {
      padding: 8px 12px; /* Even more padding reduction */
      font-size: 12px; /* Smaller font size */
    }
  }
`;

// Styled Tab
export const StyledTab = styled(Tab).withConfig({
  shouldForwardProp: (prop) => prop !== "drawerOpen", // Prevent passing drawerOpen
})`
  /* Additional styles for Tab, if needed */
  @media (max-width: 600px) {
    font-size: 14px; /* Adjust font size for smaller screens */
  }

  @media (max-width: 480px) {
    font-size: 12px; /* Even smaller font size for very small screens */
  }
`;
