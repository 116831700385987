import { createBrowserRouter, Navigate } from "react-router-dom";
import WithLazy from "./components/common/forms/WithLazy";
import ProtectedRoute from "./pages/login/ProtectedRoute";
import Login from "./pages/login/Login";
import SettingsMenuDrawer from "./pages/settings/drawer/SettingsMenuDrawer";
import NotFound404 from "./pages/404/NotFound404";
import PrivacyPolicy from "./pages/registration/PrivacyPolicy";

// Lazy load the components
const RootLayout = WithLazy(() => import("./pages/RootLayout"));
const ListToys = WithLazy(() => import("./pages/listing/ListToys"));
const Registration = WithLazy(() => import("./pages/registration/Registration"));
const OtpValidation = WithLazy(() => import("./pages/otp-validation/OtpValidation"));
const StripeConnectAccount = WithLazy(() => import("./pages/settings/stripe/StripeConnectAccount"));
// const StripeConnectAccountRefresh = WithLazy(() => import("./pages/settings/stripe/StripeConnectAccountRefresh"));
// const StripeConnectAccountReturn = WithLazy(() => import("./pages/settings/stripe/StripeConnectAccountReturn"));
const LandingPage = WithLazy(() => import("./pages/navlinks/LandingPage"));
const Product = WithLazy(() => import("./pages/product/Product"));
const AboutUs = WithLazy(() => import("./pages/navlinks/AboutUs"));
const AllListings = WithLazy(() => import("./pages/settings/listing/AllListings"));
const AllPurchases = WithLazy(() => import("./pages/settings/purchases/AllPurchases"));
const AllSales = WithLazy(() => import("./pages/settings/sold/AllSales"));

const EditInformation = WithLazy(() => import("./pages/settings/edit-information/EditInformation"));
const EditChildren = WithLazy(() => import("./pages/settings/edit-information/EditChildren"));
const UpdateToy = WithLazy(() => import("./pages/settings/listing/UpdateToy"));
const ForgotPassword = WithLazy(() => import("./pages/ForgotPassword"));

const router = createBrowserRouter([
  {
    element: <RootLayout />,
    children: [
      { path: "/", element: <LandingPage /> },
      { path: "/login", element: <Login /> },
      { path: "/home", element: <LandingPage /> },
      {
        path: "/list-toys",
        element: (
          <ProtectedRoute>
            <ListToys />
          </ProtectedRoute>
        ),
      },
      {
        path: "/update-toy/:prodId",
        element: (
          <ProtectedRoute>
            <UpdateToy />
          </ProtectedRoute>
        ),
      },
      { path: "/register-user", element: <Registration /> },
      { path: "/privacy-policy", element: <PrivacyPolicy /> },
      { path: "/activate-account", element: <OtpValidation /> },
      { path: "/product/:prodId", element: <Product /> },
      { path: "/about-us", element: <AboutUs /> },
      { path: "/forgot-password", element: <ForgotPassword /> },
      {
        path: "/settings",
        element: <SettingsMenuDrawer />,
        children: [
          {
            index: true,
            element: <Navigate to="listings" replace />,
          },
          {
            path: "listings",
            element: (
              <ProtectedRoute>
                <AllListings />
              </ProtectedRoute>
            ),
          },
          {
            path: "purchases",
            element: (
              <ProtectedRoute>
                <AllPurchases />
              </ProtectedRoute>
            ),
          },
          {
            path: "sold",
            element: (
              <ProtectedRoute>
                <AllSales />
              </ProtectedRoute>
            ),
          },
          {
            path: "edit-information",
            element: (
              <ProtectedRoute>
                <EditInformation />
              </ProtectedRoute>
            ),
          },
          {
            path: "payment-details",
            element: (
              <ProtectedRoute>
                <StripeConnectAccount />
              </ProtectedRoute>
            ),
            children: [
              {
                path: "return/:connectedAccountId",
                element: (
                  <ProtectedRoute>
                    <StripeConnectAccount />
                  </ProtectedRoute>
                ),
              },
              {
                path: "refresh/:connectedAccountId",
                element: (
                  <ProtectedRoute>
                    <StripeConnectAccount />
                  </ProtectedRoute>
                ),
              },
            ],
          },
          {
            path: "edit-children",
            element: (
              <ProtectedRoute>
                <EditChildren />
              </ProtectedRoute>
            ),
          },
        ],
      },
      { path: "*", element: <NotFound404 /> },
    ],
  },
]);

export default router;
