import { useState } from "react";
import { Grid, TextField, FormHelperText, InputAdornment, IconButton } from "@mui/material";
import { useController } from "react-hook-form";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const TextFieldComponent = ({ name, control, label, type = "text", sx, className, helperText, rules, calculateHelperText, ...rest }) => {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({ name, control, rules });

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);

  const handleChange = (e) => {
    let inputValue = e.target.value;

    // Only allow numbers and decimals
    if (name === "yourPrice") {
      inputValue = inputValue.replace(/[^0-9.]/g, "");

      // Parse the value to a float
      const parsedValue = parseFloat(inputValue);

      if (isNaN(parsedValue)) {
        inputValue = "0"; // If not a valid number, clear the input
      } else {
        // Ensure the value is no negative
        if (parsedValue < 0) {
          inputValue = "0";
        } else {
          // Round off the value
          inputValue = Math.round(parsedValue).toString();
        }
      }
    }

    onChange(inputValue);
  };

  const calculatePriceHelperText = (value) => {
    const minPrice = 10;

    if (!value || isNaN(parseFloat(value)) || parseFloat(value) < minPrice) {
      return `The price must be at least ${minPrice} NOK.`;
    }

    if (!value || isNaN(parseFloat(value))) {
      return "You will make 0.00 NOK, and the customer will pay 0.00 NOK (including a 13% service charge).";
    }
    const basePrice = parseFloat(value) || 0;
    const serviceCharge = basePrice * 0.13;
    const totalPrice = basePrice + serviceCharge;

    return `You will make ${basePrice.toFixed(2)} NOK, and the customer will pay ${totalPrice.toFixed(2)} NOK (including a 13% service charge).`;
  };

  const dynamicHelperText =
    name === "yourPrice" && value ? calculatePriceHelperText(value) : typeof helperText === "function" ? helperText(value) : helperText;

  // Check if the field is required
  const isRequired = rules?.required;
  const modifiedLabel = isRequired ? (
    <>
      {label} <span style={{ color: "red" }}>*</span>
    </>
  ) : (
    label
  );

  return (
    <Grid sx={sx}>
      <TextField
        color="secondary"
        label={modifiedLabel}
        type={type === "password" && !showPassword ? "password" : "text"}
        InputProps={{
          endAdornment: type === "password" && (
            <InputAdornment position="end">
              <IconButton onClick={togglePasswordVisibility} onMouseDown={(e) => e.preventDefault()} edge="end">
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
          onChange: handleChange,
          onBlur,
          inputRef: ref,
          value: value || "",
          error: !!error,
        }}
        fullWidth
        margin="normal"
        error={!!error} // Set error prop
        className={className}
        {...rest}
      />
      {(error || helperText) && (
        <FormHelperText error={!!error}>
          <span dangerouslySetInnerHTML={{ __html: dynamicHelperText }} />
        </FormHelperText>
      )}
    </Grid>
  );
};

export default TextFieldComponent;
