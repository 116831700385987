import { Typography, useMediaQuery } from "@mui/material";
import { useLanguageContext } from "../../../context/LanguageContext";
import { SETTINGS } from "../../../constants/NavbarConstants";

const PageTitle = ({ pageTitle }) => {
  const { t } = useLanguageContext();
  const translations = t(SETTINGS, { returnObjects: true });
  const { pageTitles } = translations;
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Typography
      variant={isMobile ? "h5" : "h4"}
      gutterBottom
      sx={{
        textAlign: isMobile ? "left" : "left",
        fontSize: isMobile ? "1.5rem" : "2rem", // Reduce font size on mobile
      }}
    >
      {pageTitles[pageTitle]}
    </Typography>
  );
};

export default PageTitle;
