import ProtectedRoute from "../pages/login/ProtectedRoute";
import EditInformation from "../pages/settings/edit-information/EditInformation";
import EditChildren from "../pages/settings/edit-information/EditChildren";
import AllListings from "../pages/settings/listing/AllListings";
import AllPurchases from "../pages/settings/purchases/AllPurchases";
import AllSales from "../pages/settings/sold/AllSales";
import CustomTooltip from "../components/common/forms/CustomTooltip";
import SetupStripeConnectAccount from "../pages/settings/stripe/StripeConnectAccount";

// Import PNG icons
const ListingsIcon = require("../assets/icons/listings.png");
const EditInfoIcon = require("../assets/icons/edit-info.png");
const PaymentIcon = require("../assets/icons/payment.png");
const ChildrenIcon = require("../assets/icons/children.png");
const PurchaseIcon = require("../assets/icons/purchases.png");
const SoldIcon = require("../assets/icons/sold.png");
//const ActionIcon = require("../assets/icons/action.png");

export const PersonalMenus = [
  {
    name: "listings",
    route: "listings",
    label: "Listings",
    icon: (
      <CustomTooltip title="Listings">
        <img src={ListingsIcon} alt="Listings" width="32" height="32" />
      </CustomTooltip>
    ),
    content: (
      <ProtectedRoute>
        <AllListings />
      </ProtectedRoute>
    ),
  },
  {
    name: "purchases",
    route: "purchases",
    label: "Purchases",
    icon: (
      <CustomTooltip title="Purchases">
        <img src={PurchaseIcon} alt="Purchases" width="32" height="32" />
      </CustomTooltip>
    ),
    content: (
      <ProtectedRoute>
        <AllPurchases />
      </ProtectedRoute>
    ),
  },
  {
    name: "sold",
    route: "sold",
    label: "Re-homed (sold)",
    icon: (
      <CustomTooltip title="Re-homed (sold)">
        <img src={SoldIcon} alt="Re-homed (sold)" width="32" height="32" />
      </CustomTooltip>
    ),
    content: (
      <ProtectedRoute>
        <AllSales />
      </ProtectedRoute>
    ),
  },
  {
    name: "editInformation",
    route: "edit-information",
    label: "Edit information",
    icon: (
      <CustomTooltip title="Edit information">
        <img src={EditInfoIcon} alt="Edit Info" width="32" height="32" />
      </CustomTooltip>
    ),
    content: (
      <ProtectedRoute>
        <EditInformation />
      </ProtectedRoute>
    ),
  },
  {
    name: "paymentDetails",
    route: "payment-details",
    label: "Payment details",
    icon: (
      <CustomTooltip title="Payment details">
        <img src={PaymentIcon} alt="Edit Info" width="32" height="32" />
      </CustomTooltip>
    ),
    content: (
      <ProtectedRoute>
        <SetupStripeConnectAccount />
      </ProtectedRoute>
    ),
  },
  {
    name: "editChildren",
    route: "edit-children",
    label: "Edit children",
    icon: (
      <CustomTooltip title="Edit children">
        <img src={ChildrenIcon} alt="Edit Info" width="32" height="32" />
      </CustomTooltip>
    ),
    content: (
      <ProtectedRoute>
        <EditChildren />
      </ProtectedRoute>
    ),
  },
];

// {
//   name: "purchases",
//   label: "Purchases",
//   content: <Purchases />,
// },
// {
//   name: "sold",
//   label: "Sold and re-homed toys",
//   content: <SoldToys />,
// },
// {
//   name: "action",
//   label: "Action!",
//   content: <p>Action content goes here.</p>,
// },
