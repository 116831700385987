import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "../config/firebase";
import { setIsAuthenticated, fetchUserProfile, setAnonymous, clearProfile } from "../redux/slices/authSlice";
import { clearCart } from "../redux/slices/cartSlice";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
      if (!user) {
        dispatch(setAnonymous());
        dispatch(clearProfile());
        return;
      }
      dispatch(setIsAuthenticated(user));
      dispatch(fetchUserProfile());
    });

    return () => unsubscribe();
    // eslint-disable-next-line
  }, []);

  const logout = async () => {
    await signOut(auth);
    setUser(null);
    dispatch(setAnonymous());
    dispatch(clearProfile());
    dispatch(clearCart()); // Reset the cart when logging out
  };

  return <AuthContext.Provider value={{ user, loading, logout }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
