import React from "react";
import ReactDOM from "react-dom/client";
import { Helmet } from "react-helmet";
import "./index.css";
import App from "./App";
import "@fontsource/libre-baskerville"; // This will load the default weight (400)
import "@fontsource/libre-baskerville/400.css"; // Regular weight
import "@fontsource/libre-baskerville/700.css"; // Bold weight

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import "./i18n";
import { LanguageContextProvider } from "./context/LanguageContext";
import { AuthProvider } from "./context/AuthProvider";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux/store";
import { SnackbarProvider } from "./context/SnackbarProvider";

const HelmetComponent = () => {
  if (process.env.REACT_APP_APP_ENV !== "production") {
    return null;
  }

  return (
  <Helmet>
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-7ZXNJS004R"></script>
    <script>
     {`
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-7ZXNJS004R');
      `}
    </script>
  </Helmet>
)}


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
  <HelmetComponent />
  <LanguageContextProvider>
    <PersistGate loading={null} persistor={persistor}>
      <Provider store={store}>
        <SnackbarProvider>
          <AuthProvider>
            <App />
          </AuthProvider>
        </SnackbarProvider>
      </Provider>
    </PersistGate>
  </LanguageContextProvider>
  </>
);
