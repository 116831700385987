import { Button } from "@mui/material";
import { styled } from "@mui/system";

// Define a styled version of Material-UI's Button component
const OutlinedButton = styled(Button)(({ customstyle }) => ({
  backgroundColor: "#510f5b",
  color: "#ffffff",
  padding: "6px 24px",
  cursor: "pointer",
  borderRadius: "25px", // Correct property name in JS is borderRadius
  "&:hover": {
    backgroundColor: "#510f5b",
  },
  textTransform: "none",
  fontWeight: "500",
  fontSize: "0.875rem",
  "@media (max-width:600px)": {
    padding: "4px 16px", // Custom padding for small devices (<= 600px)
  },
  ...customstyle, // Merge any additional custom styles
}));

export default OutlinedButton;
