import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URLS } from "../../utils/apiConstants";
import apiClient from "../../utils/axiosInstance";

// Generic API handler function
const apiRequest = async (method, url, data, rejectWithValue) => {
  try {
    const response = await apiClient({ method, url, data });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data || error.message);
  }
};

// API Thunks using existing reducers
export const addToCart = createAsyncThunk("cart/addToCart", async (productUid, { dispatch, rejectWithValue }) => {
  const updatedCart = await apiRequest("post", API_URLS.ADD_TO_CART, productUid, rejectWithValue);
  dispatch(updateCart(updatedCart)); // Updates Redux cart state
  return updatedCart;
});

export const removeFromCart = createAsyncThunk("cart/removeFromCart", async (itemId, { dispatch, rejectWithValue }) => {
  const updatedCart = apiRequest("post", API_URLS.REMOVE_FROM_CART, itemId, null, rejectWithValue);
  dispatch(deleteItem(itemId)); // Updates Redux cart state
  return updatedCart;
});

export const clearCartAPI = createAsyncThunk("cart/clearCart", async (_, { rejectWithValue }) =>
  apiRequest("post", API_URLS.CLEAR_CART, null, rejectWithValue)
);

const initialState = {
  items: [],
  isCartDrawerOpen: false,
  selectedToy: null,
  loading: false,
  error: null,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setSelectedToy(state, action) {
      state.selectedToy = action.payload;
    },
    clearSelectedToy(state) {
      state.selectedToy = null;
    },
    addItem(state, action) {
      const { uid, title, sellingPrice: price, media } = action.payload;
      const firstImage = media.find((item) => item.type === "image");
      const newItem = { id: uid, title, price, image: firstImage };

      if (!state.items.find((item) => item.id === newItem.id)) {
        state.items.unshift(newItem);
      }
    },
    updateCart(state, action) {
      state.items = [...action.payload].reverse();
    },
    deleteItem(state, action) {
      state.items = state.items.filter((item) => item.id !== action.payload);
    },
    clearCart(state) {
      state.items = [];
    },
    toggleCartDrawer(state, action) {
      state.isCartDrawerOpen = action.payload;
    },
    initializeCartItems(state, action) {
      state.items = action.payload;
    },
  },
  extraReducers: (builder) => {
    const handlePending = (state) => {
      state.loading = true;
      state.error = null;
    };

    const handleRejected = (state, action) => {
      state.loading = false;
      state.error = action.payload;
    };
    builder
      // Add to Cart
      .addCase(addToCart.pending, handlePending)
      .addCase(addToCart.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(addToCart.rejected, handleRejected)

      // Remove from Cart
      .addCase(removeFromCart.pending, handlePending)
      .addCase(removeFromCart.fulfilled, (state, action) => {
        state.loading = false;
        state.items = action.payload;
      })
      .addCase(removeFromCart.rejected, handleRejected)

      // Clear Cart
      .addCase(clearCartAPI.pending, handlePending)
      .addCase(clearCartAPI.fulfilled, (state) => {
        state.loading = false;
        state.items = [];
      })
      .addCase(clearCartAPI.rejected, handleRejected);
  },
});

export const { addItem, deleteItem, clearCart, toggleCartDrawer, setSelectedToy, clearSelectedToy, initializeCartItems, updateCart } =
  cartSlice.actions;

export const selectCartLoading = (state) => state.cart.loading;
export const selectCartItems = (state) => state.cart.items;
export const selectTotalPrice = (state) => {
  const cartItems = state.cart.items || []; // Ensure cartItems is always an array
  return parseFloat(cartItems.reduce((sum, item) => sum + (item?.sellingPrice || 0), 0).toFixed(2));
};
export const selectIsCartDrawerOpen = (state) => state.cart.isCartDrawerOpen;
export const getSelectedToy = (state) => state.cart.selectedToy;

export default cartSlice.reducer;
