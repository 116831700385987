import { useMemo } from "react";
import moment from "moment";
import { useMediaQuery } from "@mui/material";

// Function to abbreviate text
const abbreviateText = (text, maxLength) => {
  if (!text) return "NA"; // Handle null or undefined memory
  return text.length > maxLength ? text.slice(0, maxLength) + "..." : text;
};

// Function to format date
const formatDate = (date) => {
  return moment(date).format("DD.MM.YYYY"); // Format the date
};

// Function to get helper text for a field
const getHelperTextForField = (fields, fieldName, value) => {
  const field = fields.find((field) => field.name === fieldName);
  if (!field || !field.helpertextmap) return {};

  const selectedCondition = field.helpertextmap[value];
  const { subHelperText, label } = selectedCondition || {};

  return {
    condition: selectedCondition,
    subHelperText: subHelperText || "",
    label: label || "",
  };
};

const useFormatUtils = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const maxLength = isMobile ? 10 : 30;

  return useMemo(
    () => ({
      abbreviateText: (text) => abbreviateText(text, maxLength),
      formatDate,
      getHelperTextForField,
    }),
    [maxLength]
  );
};

export default useFormatUtils;
