import { Container, Typography, Tooltip } from "@mui/material";
import PrivacyPolicySection from "./PrivacyPolicySection";

// Reusable Section Component for Headings and Content

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md" sx={{ py: 4 }}>
      {/* Page Header */}
      <Typography
        variant="h4"
        fontWeight="bold"
        gutterBottom
        align="center"
        sx={{ color: "#510f5b" }} // Custom header color
      >
        Privacy Policy
      </Typography>
      <Typography variant="body2" align="center" color="textSecondary">
        Last updated: January 2025
      </Typography>
      <PrivacyPolicySection
        content={
          <>
            The Loopyty platform is delivered by the Norwegian company Loopyty AS. In the following, references to “we,” “us,” and “our” refer to
            Loopyty AS. We process personal data according to the General Data Protection Regulation (GDPR). This privacy statement provides an
            overview of how we do so. If you have any further questions, you can contact us at{" "}
            <a href="mailto:support@loopyty.com" color="#510f5b">
              support@loopyty.com
            </a>
            .
          </>
        }
      />
      {/* Section for "About the Loopyty Platform" */}
      <PrivacyPolicySection
        title="About the Loopyty Platform"
        content="Loopyty is an independent digital service, a technology company, and a child-friendly marketplace that helps parents and children manage toy consumption sustainably from the beginning to the end. Dream, purchase, play, and part with pre-loved toys in a new and personal way to save waste. Join us in saving the planet with sustainable toy stories. Loop your toy!"
      />
      {/* Section for "Data We Collect About You" */}
      <PrivacyPolicySection
        title="Data We Collect About You"
        content={
          <>
            <strong>User Account Information:</strong> When creating a profile, we collect:
            <ul>
              <li>Name</li>
              <li>Username</li>
              <li>Email</li>
              <li>Phone number</li>
              <li>Birthday</li>
              <li>Address</li>
            </ul>
            Adding your children's names, birthdays, and interests is optional.
            <br />
            We aim to make a platform that is safe for children. Therefore, we collect this information to verify that the users are authentic. We
            store this information until you delete your account. The data may be stored longer if required to defend a legal claim, for example, if
            we suspect that you have provided us with false information violating our terms and conditions. The data is only used to administer your
            user account and offer access to the Loopyty.
          </>
        }
        isBulletList={false}
      />
      {/* Customer Information */}
      <PrivacyPolicySection
        title="Customer Information"
        content="If you use Loopyty's selling or purchasing services, you will be asked to provide your credit card and user account information. Our payment provider, Stripe, will store this information. We will store information about your payment history and related personal data (name and contact information) for five years by the Norwegian Bookkeeping Act § 13. "
        contentVariant="h6"
      />
      <PrivacyPolicySection
        title="The content you share"
        content="We store information about any product you choose to share with other users on Loopyty. If you delete your account, this information is deleted from your user account. However, if you have shared it with others, the information may still be available for them on the platform."
        contentVariant="h6"
      />
      <PrivacyPolicySection
        title="Your interactions"
        content="We collect information about how Loopyty is used. This includes information about the number of users active on the platform at any given time, how they interact with the content and their location. We only collect this information based on your prior consent. /nInformation is collected using information capsules (cookies)—the cookies we use to store information for two (2) years. You may withdraw your consent at any time by disabling cookies or deleting the cookies in your browser. "
        contentVariant="h6"
      />
      <PrivacyPolicySection
        title="Communicating with you"
        content="We may contact you to verify your account or, in the case of security breaches, provide you with other critical information. /nWe may also send you promotional offers and general information about our services. You may opt out of our information newsletter at any time. Please see the “unsubscribe” link at the bottom of each of the newsletters that you have received."
        contentVariant="h6"
      />
      {/* Sharing Information */}
      <PrivacyPolicySection
        title="Sharing Information"
        content="We share personal data about you with our data processors, who will only process your data in accordance with our instructions. Our current data processor is Stripe Inc., which delivers payment services for Loopyty. /nWe also use cookies and similar technologies to share information about your interactions on the platform with Google services, like Google Cloud Platform./nApart from the companies named above, we do not disclose or share your data with third parties except where necessary to fulfil our legal obligations. We may, for example, disclose our data to the police or other public authorities if we are required to do so by law."
      />
      {/* Your Rights */}
      <PrivacyPolicySection
        title="Your Rights"
        content="You have the right to request information from us whenever you have questions regarding how we process your data. In addition, you have the right to access, correct or delete your data at any time./nIf you have concerns about how we process personal data, you may also complain to your local data protection authority."
      />
      {/* Cookies & Similar Technologies */}
      <PrivacyPolicySection
        title="Cookies & Similar Technologies"
        content="Cookies are small text files stored on your computer with your consent. They enable us to collect information about how you use the service. We use cookies and similar technologies on Loopyty for functional purposes (to verify the user while the user is logged in) and to provide relevant information about our services when our users visit Google Search. "
      />
      {/* GOOGLE CLOUD PLATFORM (GCP) */}
      <PrivacyPolicySection
        title="GOOGLE CLOUD PLATFORM (GCP)"
        content={
          <>
            We store and process your data on the Google Cloud Platform. This reliable and secure cloud computing platform adheres to strict security
            and privacy standards.By leveraging Google Cloud, we ensure the confidentiality and integrity of your information.{" "}
            <Tooltip title="Read more about Security in Google Cloud" arrow>
              <a
                href="https://cloud.google.com/trust-center/security?hl=en"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ textDecoration: "none", color: "#510f5b", fontWeight: "bold" }}
              >
                Read more about Security in Google Cloud
              </a>
            </Tooltip>
          </>
        }
        contentVariant="h6"
      />
      {/* Data Security */}
      <PrivacyPolicySection
        title="Data Security"
        content="We have implemented security safeguards based on generally accepted industry standards to protect personal data from unauthorized access, use, disclosure, alteration, or loss."
      />
      <PrivacyPolicySection
        title="Communicating with you"
        content={
          <>
            Stripe is the payment processing service for registered users on Pluvinel. Stripe has been audited by a PCI-certified auditor and is
            certified to{" "}
            <Tooltip title="https://www.visa.com/splisting/searchGrsp.do?companyNameCriteria=stripe" arrow>
              <a
                href="https://www.visa.com/splisting/searchGrsp.do?companyNameCriteria=stripe"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ textDecoration: "none", color: "#510f5b", fontWeight: "bold" }}
              >
                PCI Service Provider Level 1.
              </a>
            </Tooltip>
            This is the most stringent level of certification available in the payments industry. To accomplish this, Stripe uses best-in-class
            security tools and practices to maintain a high level of security at Stripe. <br />
            <br /> All card numbers are encrypted on disk with AES-256, and decryption keys are stored on separate machines. None of Stripe’s internal
            servers and daemons can obtain plaintext card numbers; instead, they can request that cards be sent to a service provider on a static
            whitelist.
            <br />
            <Tooltip title="https://stripe.com/docs/security/stripe" arrow>
              <a
                href="https://docs.stripe.com/security"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ textDecoration: "none", color: "#510f5b", fontWeight: "bold" }}
              >
                Read more about Security at Stripe here.
              </a>
            </Tooltip>
          </>
        }
        contentVariant="h6"
      />
    </Container>
  );
};

export default PrivacyPolicy;
