import { updateCart } from "../redux/slices/cartSlice";

const channel = new BroadcastChannel("cart_channel");
let lastUpdatedByThisTab = false;

// Function to listen for cart updates from other tabs
export const setupCartSync = (store) => {
  channel.onmessage = (event) => {
    if (event.data.type === "UPDATE_CART") {
      const currentCart = store.getState().cart.items;
      const newCart = event.data.payload;

      // Prevent redundant updates
      if (JSON.stringify(currentCart) !== JSON.stringify(newCart)) {
        lastUpdatedByThisTab = true;
        store.dispatch(updateCart(newCart));
      }
    }
  };

  // Watch for cart updates and notify other tabs
  store.subscribe(() => {
    const state = store.getState();
    const cartItems = state.cart.items;

    if (!lastUpdatedByThisTab) {
      channel.postMessage({ type: "UPDATE_CART", payload: cartItems });
    }

    lastUpdatedByThisTab = false;
  });
};
