import { Typography, useMediaQuery } from "@mui/material";
import { useLanguageContext } from "../../../context/LanguageContext";
import { SETTINGS } from "../../../constants/NavbarConstants";

const PageSubTitle = ({ pageSubTitle }) => {
  const { t } = useLanguageContext();
  const translations = t(SETTINGS, { returnObjects: true });
  const { pageSubTitles } = translations;
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Typography
      variant={isMobile ? "h6" : "h5"}
      gutterBottom
      sx={{
        textAlign: "left",
      }}
    >
      {pageSubTitles[pageSubTitle]}
    </Typography>
  );
};

export default PageSubTitle;
