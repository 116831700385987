import { useNavigate } from "react-router-dom";
import { PURCHASES } from "../../../constants/NavbarConstants";
import ActionIcons from "../corelist/ActionIcons";
import PageSubTitle from "../corelist/PageSubTitle";
import ReusableListView from "../corelist/ReusableListView";
import SecondaryText from "../corelist/SecondaryText";
import useFormatUtils from "../../../hooks/useFormatUtils";

const Purchases = ({ purchasedItems }) => {
  const navigate = useNavigate();
  const { formatDate } = useFormatUtils();

  const renderAction = (item) => <ActionIcons onRelist={() => handleRelist(item)} />;

  const renderSecondaryText = (item) => (
    <SecondaryText
      fields={[
        { label: "Previous owner", value: item.previousOwner || "NA" },
        { label: "Purchased date", value: item.purchasedDate ? formatDate(item.purchasedDate) : "NA" },
        { label: "Price you paid", value: `${item.purchasedPrice} NOK` || "NA" },
      ]}
    />
  );

  const handleRelist = (item) => {
    navigate(`/update-toy/${item.uid}`);
  };

  const loadMoreItems = () => {};

  return (
    <>
      <PageSubTitle pageSubTitle={PURCHASES} />
      <ReusableListView items={purchasedItems} loadMoreItems={loadMoreItems} renderAction={renderAction} renderSecondaryText={renderSecondaryText} />
    </>
  );
};

export default Purchases;
