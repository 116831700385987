import { styled } from "@mui/material/styles";

const StyledDrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  height: "6rem",
}));

export default function DrawerHeader({ children }) {
  return <StyledDrawerHeader>{children}</StyledDrawerHeader>;
}
