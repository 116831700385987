import { useState } from "react";
import { useDispatch } from "react-redux";
import { unpublishPopupConfig } from "../../../constants/ListingActionPopupConfig";
import ActionConfirmPopup from "../corelist/ActionConfirmPopup";
import ReusableListView from "../corelist/ReusableListView";
import PageSubTitle from "../corelist/PageSubTitle";
import useFormatUtils from "../../../hooks/useFormatUtils";
import SecondaryText from "../corelist/SecondaryText";
import ActionIcons from "../corelist/ActionIcons";
import { useSnackbar } from "../../../context/SnackbarProvider";
import { useNavigate } from "react-router-dom";

const Published = ({ publishedItems, onUpdateListings }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();
  const [isUnpublishPopupOpen, setIsUnpublishPopupOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { abbreviateText, formatDate } = useFormatUtils();

  const unpublishConfig =
    isUnpublishPopupOpen && selectedItem
      ? unpublishPopupConfig(dispatch, setIsUnpublishPopupOpen, selectedItem.uid, (message, type) => {
          showSnackbar(message, type);
          onUpdateListings(); // Refresh the listings
        })
      : { open: false };

  const renderAction = (item) => <ActionIcons onSend={() => handleSend(item)} onEdit={() => handleEdit(item)} />;

  const handleSend = (item) => {
    setSelectedItem(item);
    setIsUnpublishPopupOpen(true);
  };

  const handleEdit = (item) => {
    navigate(`/update-toy/${item.uid}`);
  };

  const renderSecondaryText = (item) => (
    <SecondaryText
      fields={[
        { label: "Best memory", value: abbreviateText(item.memory, 20) || "NA" },
        { label: "Listed date", value: item.updatedAt ? formatDate(item.updatedAt) : "NA" },
        { label: "Price", value: `${item.sellingPrice} NOK` || "NA" },
      ]}
    />
  );

  const loadMoreItems = () => {};

  return (
    <>
      <ActionConfirmPopup config={unpublishConfig} />
      <PageSubTitle pageSubTitle={"published"} />
      <ReusableListView items={publishedItems} loadMoreItems={loadMoreItems} renderAction={renderAction} renderSecondaryText={renderSecondaryText} />
    </>
  );
};

export default Published;
