import { Checkbox, FormControlLabel, FormHelperText, Typography, Box, Link } from "@mui/material";
import { useController } from "react-hook-form";
import { Link as RouterLink } from "react-router-dom"; // For internal navigation

const CheckboxComponent = ({ name, control, label, options, rules, helperText }) => {
  const {
    field: { onChange, value = false }, // Default value to an empty array
    fieldState: { error },
  } = useController({ name, control, rules });

  // Check if the field is required
  const isRequired = rules?.required;

  // Function to handle consent text with links (e.g., Privacy Policy)
  const renderConsentWithLink = (text) => {
    // Check for the placeholder <link> tag
    const parts = text.split("<link>");
    if (parts.length > 1) {
      return (
        <>
          {parts[0]}
          <Link component={RouterLink} to="/privacy-policy" target="_blank" sx={{ color: "#510f5b", fontWeight: "bold" }}>
            {parts[1].split("</link>")[0]} {/* Extract the link text */}
          </Link>
          {parts[1].split("</link>")[1]}
        </>
      );
    }
    return text; // Return the original text if no <link> tag is found
  };

  return (
    <>
      <Box mt={2} display="flex" alignItems="end">
        <FormControlLabel
          sx={{ marginRight: 0 }}
          control={<Checkbox color="secondary" onChange={(e) => onChange(e.target.checked)} checked={value} />}
        />
        <Typography mb={1} ml={0} variant="subtitle1">
          {renderConsentWithLink(label)} {isRequired && <span style={{ color: "red" }}>*</span>}
        </Typography>
      </Box>
      {error && <FormHelperText error>{helperText}</FormHelperText>}
    </>
  );
};

export default CheckboxComponent;
